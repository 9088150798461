export type LoginMethod = 'line' | 'email';
export const usePreviousLoginMethod = () => {
  const previousLoginMethod = useLocalStorageState<LoginMethod | null>(
    'previousLoginMethod',
    null
  );

  const setPreviousLoginMethod = (method: LoginMethod) => {
    previousLoginMethod.value = method;
  };

  return {
    previousLoginMethod: readonly(previousLoginMethod),
    setPreviousLoginMethod,
  };
};
